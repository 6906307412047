<template>
  <b-form>
    <b-card title="Proje Güncelle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="realtyProject.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Durum"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="realtyProject.status"
          :options="options"
          :reduce="option => option.id"
          placeholder="Durum"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      options: [{ id: '1', label: 'Aktif' }, { id: '0', label: 'Pasif' }],
    }
  },
  computed: {
    realtyProject() {
      return this.$store.getters['realtyProjects/getRealty_sale_project']
    },
    saveStatus() {
      return this.$store.getters['realtyProjects/getRealty_sale_projectSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.realtyProject = {
          id: null,
          title: null,
          status: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('realtyProjects/realty_sale_projectView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('realtyProjects/realty_sale_projectSave', this.realtyProject)
    },
  },
}
</script>
